<template>
  <section class="sign-up h-100">
    <div class="flex h-screen">
      <div class="m-auto lg:ml-40">
        <div class="mb-5">
          <h3 class="text align-self-center mr-auto">
            Sign In
          </h3>

          <p class="text align-self-center mr-auto">
            New to Relatus? <router-link
              :to="{name: 'signup'}"
              class="underline"
            >
              Get Started Now
            </router-link>
          </p>
        </div>

        <form
          class="mt-10"
          @submit.prevent="login"
        >
          <div
            v-if="errorMessage"
            class="flex items-center text-white text-sm font-bold mb-5 alert-error"
            role="alert"
          >
            <img
              src="@/assets/images/ic_danger.svg"
              class="mr-2"
            >
            <p>{{ errorMessage }}</p>
          </div>

          <div class="mb-3">
            <input
              v-model="email"
              type="email"
              class="form-control"
              placeholder="Username or Email"
              required
            >
          </div>
          <div class="mb-3">
            <input
              v-model="password"
              type="password"
              class="form-control"
              placeholder="Password"
              required
            >
          </div>

          <p class="text align-self-center mr-auto">
            <router-link
              :to="{name: 'forgot.password'}"
              class="text-base underline"
            >
              Forgot Password
            </router-link>
          </p>

          <div class="mt-7">
            <button
              type="submit"
              class="btn btn-primary"
            >
              Sign In
            </button>
          </div>
        </form>

        <p class="text-white text-start mt-8">
          -or sign in with-
        </p>

        <ul class="flex iteml-center mt-6">
          <li class="mr-5">
            <a
              href="#"
            ><img
              src="@/assets/images/ic_linkedin.svg"
              class="social-links"
              alt="Sign in with LinkedIn"
            ></a>
          </li>
          <li class="mr-5">
            <a
              href="#"
            ><img
              src="@/assets/images/ic_google.svg"
              class="social-links"
              alt="Sign in with Google"
            ></a>
          </li>
          <li>
            <a
              href="#"
            ><img
              src="@/assets/images/ic_twitter.svg"
              class="social-links"
              alt="Sign in with Twitter"
            ></a>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import { getAuth, signInWithEmailAndPassword } from "@firebase/auth";
// import axios from 'axios'

export default {
  name: 'Signin',
  data() {
    return {
      email: "",
      password: "",
      errorMessage: null
    };
  },
  methods: {
    login() {
      const auth = getAuth();
      signInWithEmailAndPassword(auth, this.email, this.password)
        .then((userCredential) => {
          // const user = userCredential.user;
          console.log(userCredential);

          this.$router.push({
            path: '/instructions'
          });

          // let data = {
          //   user_id: user.uid,
          //   first_name: 'Pedro',
          //   last_name: 'Jordao',
          //   email: user.email,
          //   phone: '9183843311'
          // }
          // axios.post('https://relatus-profile-api-dev-7kupcr44na-uc.a.run.app/v1/user', data, {
          //   headers: {
          //     'Authorization': `Bearer ${user.accessToken}`
          //   }
          // })
          // .then((response) => {
          //   console.log(response);
          // })
          // .catch((error) => {
          //   console.log(error);
          // });

        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.error(errorCode, errorMessage);

          if (errorCode === 'auth/wrong-password') {
            this.errorMessage = 'Incorret username or password.';
          } else if (errorCode === 'auth/invalid-email') {
            this.errorMessage = 'Invalid email address.';
          }
        });
    },
  }
}
</script>

<style scoped>
.sign-up {
  background-image: url('~@/assets/images/onboarding.jpg');
  background-size: cover;
  background-position: 50%;
  height: 100vh!important;
}
h3 {
  font-family: 'Helvetica';
  font-size: 34px;
  line-height: 50px;
  color: #FFFFFF;
  letter-spacing: 1px;
  font-weight: 400;
}
h3 > strong {
  font-weight: bold;
}
.sign-up p {
  font-family: 'Helvetica';
  font-size: 22px;
  line-height: 32px;
  color: #FFFFFF;
  margin-bottom: 0;
  font-weight: 300;
}
.sign-up .social-links {
  background: rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  border-radius: 45px;
  padding: 10px;
  min-height: 50px;
}
.sign-up .social-links img {
  height: 24px;
}

/* FIX: Input must be a component */
input {
  background: rgba(0, 0, 0, 0.5)!important;
  color: #FFFFFF!important;
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  border-radius: 30px;
  padding: 10px;
  min-width: 250px;
}
input::placeholder {
  color: #FFFFFF!important;
  opacity: 1!important;
}
input:-ml-input-placeholder {
  color: #FFFFFF!important;
}
input::-ml-input-placeholder {
  color: #FFFFFF!important;
}
.btn-primary {
  background-color: #FF6600;
  color: #FFFFFF;
  padding: 10px;
  border: 1px solid #FF6600;
  box-sizing: border-box;
  border-radius: 35px;
}
.alert-error {
  background: rgba(217, 54, 31, 0.3);
  border-radius: 10px;
  padding: 10px;
}
.alert-error p {
  font-size: 14px;
}
</style>
